<template>
  <div class="ol-background">
    <Header />
    <div class="container d-flex ol-structure">
      <div class="d-flex">
        <div class="container-address">
          <Alert v-show="alertList.message != null" :type="alertList.type" :message="alertList.message" />
          <h2 class="ol-dashboard__order-address-item-title">
            Alamat Pengiriman
          </h2>
          <a href="#" @click="showPopupListAddress(true)" class="d-flex address-content-container">
            <div v-if="addresses.length < 1" class="d-flex flex-column">
              <label class="ol-checkout-content__shipping-list-body-wrapper">
                Belum ada Alamat yang disimpan
              </label>
            </div>
            <div v-else class="d-flex flex-column">
              <p class="address-shipping-name">{{ shipping.name }}</p>
              <p class="address-shipping-phone">{{ shipping.phone }}</p>
              <p class="address-shipping-detail">
                {{ shipping.address }}, {{ shipping.postal_code }},
                {{ shipping.village_name }}, Kec.&nbsp;{{
                  shipping.district_name
                }},&nbsp;{{ shipping.city_name }},&nbsp;{{
                  shipping.province_name
                }}
              </p>
            </div>
            <div class="d-flex align-items-center">
              <i class="ol-fa fas fa-arrow-right"></i>
            </div>
          </a>
          <a href="javascript:void(0)" @click="showPopupAddAddress(true, true)" class="ol-address-add-btn">
            Tambah Alamat Baru</a>
        </div>
        <div class="container-product">
          <div class="ol-checkout-content">
            <div class="ol-cart">
              <div class="ol-cart-list">
                <div v-if="loadingSummary" class="ol-dashboard__order-address">
                  <SkeletonLoader height="90px" width="100%" /><br />
                  <SkeletonLoader height="90px" width="100%" /><br />
                  <SkeletonLoader height="90px" width="100%" /><br />
                </div>
                <div v-else>
                  <div class="ol-cart">
                    <div class="ol-cart-list" id="keranjang">
                      <div class="shopping-cart">
                        <div class="column-labels">
                          <label class="product-check">Check</label>
                          <label class="product-image">Pilih Semua</label>
                          <label class="product-details">Produk</label>
                          <label class="product-price">Harga</label>
                          <label class="product-quantity">Jumlah</label>
                          <label class="product-removal">Hapus</label>
                          <label class="product-line-price">Total</label>
                        </div>
                        <div v-if="summary" class="ol-cart-list__body">
                          <div v-for="item in summary.cart_items" :key="item.id" class="list-cart-product">
                            <div class="product">
                              <div class="product-image">
                                <router-link :to="'/product/' + item.variant_detail.slug">
                                  <img v-if="
                                    item.variant_detail.r_uni_product_image
                                  " :src="item.variant_detail.r_uni_product_image
                                    .image_url
                                    " :alt="item.variant_detail.name_long" :title="item.variant_detail.name_long" />
                                  <img v-else :src="noImage()" :alt="item.variant_detail.name_long"
                                    :title="item.variant_detail.name_long" />
                                </router-link>
                              </div>
                              <div class="product-details">
                                <div class="product-title">
                                  <router-link :to="'/product/' + item.variant_detail.slug">
                                    <h3 class="ol-cart-list__body-item-desc-name">
                                      {{ item.variant_detail.name_long }}
                                    </h3>
                                    <span v-if="
                                      item.r_more_transaction_cart_bundlings
                                        .length < 1
                                    " class="ol-cart-list__body-item-desc-type">
                                      <!-- {{item.variant_detail.variant}} -->
                                    </span>
                                    <span v-else class="ol-cart-list__body-item-desc-type">
                                      <span v-for="detail in item.r_more_transaction_cart_bundlings"
                                        :key="detail.id + 'detail-bundle'">
                                        {{
                                          detail.r_uni_product_variants
                                            .name_short
                                        }}
                                        <b>x</b> {{ detail.quantity }}<br /></span>
                                    </span>
                                  </router-link>
                                </div>
                                <span v-if="item.r_uni_product_variants">
                                  <span v-if="
                                    item.r_uni_product_variants.r_uni_product
                                      .variant_1_name &&
                                    item.r_uni_product_variants.r_uni_product
                                      .variant_2_name
                                  ">
                                    <p class="product-description">
                                      {{
                                        item.r_uni_product_variants
                                          .r_uni_product.variant_1_name
                                      }}
                                      : {{ item.variant_detail.variant }},
                                      {{
                                        item.r_uni_product_variants
                                          .r_uni_product.variant_2_name
                                      }}
                                      : {{ item.variant_detail.color }}
                                    </p>
                                  </span>
                                  <span v-else>
                                    <p class="product-description">
                                      Varian :
                                      {{ item.variant_detail.variant }}&nbsp;
                                    </p>
                                  </span>
                                </span>
                                <span v-else>
                                  <p class="product-description">
                                    Varian :
                                    {{ item.variant_detail.variant }}&nbsp;
                                  </p>
                                </span>
                                <!-- <p class="product-description">Varian : {{item.variant_detail.variant}}&nbsp;</p> -->
                              </div>
                              <div class="product-price">
                                <span class="ol-home-product__content-item-list--item-price-real" v-if="
                                  item.variant_detail
                                    .r_uni_product_variant_prices
                                    .more_discount &&
                                  item.variant_detail
                                    .r_uni_product_variant_prices
                                    .more_discount != '0%'
                                ">
                                  {{
                                    item.variant_detail
                                      .r_uni_product_variant_prices.ho_normal_rp
                                  }}</span>
                                <small class="ol-home-product__content-item-list--item-price-discount">
                                  <!-- {{!item.is_flashsale ? item.variant_detail.r_uni_product_variant_prices.more_discount_price_rp : item.sub_total_rp}} -->
                                  {{ item.sub_total_rp }}
                                </small>
                              </div>
                              <div class="product-quantity">
                                {{ item.quantity }} Barang
                              </div>
                              <div class="product-removal">&nbsp;</div>
                              <div class="product-line-price ol-home-product__content-item-list--item-price-discount">
                                {{ item.sub_total_rp }}
                              </div>
                            </div>
                            <div v-if="
                              item.variant_detail.pre_checkout_status == false
                            " class="ol-alert-static ol-alert--danger" id="wrongStok"
                              style="display: block; margin-top: 15px !important">
                              <div class="row no-gutters">
                                <div class="col-7 d-flex align-items-center info-hubungi-admin">
                                  {{ item.variant_detail.pre_checkout_message }}
                                </div>
                                <div class="col-5 btn-hubungi-admin">
                                  <div v-if="
                                    item.variant_detail.pre_checkout_message.includes(
                                      'sedang habis'
                                    )
                                  ">
                                    <a :href="'/product/' +
                                      item.variant_detail.slug +
                                      '#rekomendasi'
                                      " class="ol-btn" style="padding: 4px 15px">
                                      Pilih Produk Serupa
                                    </a>
                                  </div>
                                  <div v-else>
                                    <a @click="
                                      chatDelivery(
                                        item.variant_detail.name_long,
                                        shipping.city_name,
                                        shipping.province_name
                                      )
                                      " target="_blank" class="ol-btn" style="padding: 4px 15px">Hubungi Admin</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="total-estimation d-flex justify-content-between align-items-center">
            <p>Total Pesanan</p>
            <p v-if="summary" class="total">
              {{ summary.summary.cart_grand_total_rp }}
            </p>
          </div> -->
        </div>
      </div>
      <div class="d-flex">
        <div class="container-voucher">
          <form v-on:submit.prevent="" class="d-flex justify-content-start align-items-center">
            <input v-if="appliedVoucher" id="couponvalue" style="text-transform: uppercase" type="text" name=""
              class="ol-input voucher-code-input d-none" placeholder="Masukkan Kode Voucher" v-model="input.kode" />
            <button v-show="!loadingVoucher && !appliedVoucher" type="button"
              class="voucher-button d-flex justify-content-between align-items-center" @click="showPopupVoucher()">
              <p>Voucher MORE</p>
              <div class="d-flex flex-row align-items-center gap-5px">
                <p v-if="appliedVoucher">{{ input.kode }}</p>
                <p v-else class="text-colored">Gunakan/ masukkan kode</p>
                <i class="ol-fa fas fa-arrow-right"></i>
              </div>
            </button>
            <button v-show="!loadingVoucher && appliedVoucher" type="button" class="ol-btn-coupon voucher-cancel"
              style="margin-left: 15px;" @click="hapusVoucher()">
              <i class="fas fa-times ol-fa"></i>
            </button>
            <button v-show="loadingVoucher" type="button" class="ol-btn-coupon" style="pointer-events: none">
              <i class="fas fa-spinner fa-pulse ol-fa"></i>&nbsp;Terapkan
            </button>
          </form>
          <div class="promoalert" id="infopesan" style="text-align: left">
            <p class="info-validation">
              {{ alertVoucher.pesan }}
            </p>
          </div>
        </div>
        <div class="container-payment">
          <a href="#" @click="showPopupListPayment(true)"
            class="payment-button d-flex justify-content-between align-items-center">
            <p>Metode Pembayaran</p>
            <div class="d-flex flex-row align-items-center gap-5px">
              <p v-if="payment != null && payment.code != null">
                {{ payment.type + " (" + payment.name + ") " + tenor_txt }}
              </p>
              <p v-else class="text-colored">Pilih Metode Pembayaran</p>
              <i class="ol-fa fas fa-arrow-right"></i>
            </div>
          </a>
        </div>
        <div class="container-payment-detail">
          <h2 class="ol-dashboard__order-address-item-title">
            Rincian Pembayaran
          </h2>
          <div class="ol-cart-list__footer-item" style="width: 100%">
            <dl class="ol-cart-list__footer-item-total">
              <dt>Subtotal Produk</dt>
              <dd v-if="summary">{{ summary.summary.cart_grand_total_rp }}</dd>
              <dt>Subtotal Pengiriman</dt>
              <dd v-if="summary">{{ summary.summary.shipping_price_rp }}</dd>
              <!-- // TODO: uncomment tampilan biaya layanan -->
              <!-- <dt v-if="fee">Biaya Layanan</dt>
                            <dd v-if="fee">
                                {{ fee_rp }}
                            </dd> -->
              <dt class="font-16 title"><strong>Total</strong></dt>
              <dd class="font-16 title">
                <strong v-if="summary">{{
                  summary.summary.grand_total_rp
                }}</strong>
              </dd>
              <dt v-if="appliedVoucher" class="total-voucher lbl-voucher">
                Voucher #{{ voucher ? voucher.code : "" }}
              </dt>
              <dd v-if="appliedVoucher" class="total-voucher potongan-voucher">
                -{{ voucher ? diskon : "" }}
              </dd>
              <dt v-if="appliedVoucher" class="total-voucher font-16 title">
                <strong>Total Pembayaran</strong>
              </dt>
              <dd v-if="appliedVoucher" class="total-voucher font-16 title">
                <strong class="info-grand-total" v-if="summary">{{
                  summary.summary.bill_total_rp
                }}</strong>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <div class="ol-wrapper ol-wrapper--large" style="">
      <div class="ol-checkout-nav">
        <button v-if="loadingBayar" type="button" class="ol-btn" style="pointer-events: none">
          Bayar &emsp;<i class="fas fa-spinner fa-pulse ol-fa"></i>
        </button>
      </div>
      <section class="ol-checkout-couponcarousel">
        <div class="ol-wrapper ol-wrapper--large">
          <div id="myCoupon" class="modal-coupon" style="display: block" v-show="modal.showing">
            <!-- Modal content -->
            <div class="modal-coupon-content" v-if="modal.data != null">
              <span class="close-coupon" v-on:click="() => {
                this.modal.showing = false;
              }
                ">&times;</span>
              <div class="row">
                <div class="">
                  <div class="imgCoupon">
                    <img :srcset="modal.data.image" class="voucher-image" style="width: 100%" />
                  </div>

                  <div class="coupontitle">
                    <h1>
                      Voucher
                      <span class="voucher">{{
                        renderPotongan(modal.data)
                      }}</span>
                    </h1>
                    <h2 class="voucher-code">
                      {{ modal.data.code }}
                    </h2>
                    <div style="display: flex">
                      <div style="display: flex; align-items: center">
                        <i class="fas fa-clock fa-sm" style="margin-right: 10px; color: #364b7b"></i>
                      </div>

                      <p>Berlaku Hingga</p>
                      <strong class="voucher-expired">
                        {{ modal.data.expired }}</strong>
                    </div>
                  </div>

                  <div class="snk">
                    <h2>Syarat dan Ketentuan</h2>
                    <div class="voucher-snk" v-html="modal.data.term_condition"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BannerBottomLoading v-if="loading" />
        </div>
      </section>
      <Popup title="Gunakan Voucher" id="popupVoucher">
        <div class="ol-popup-box__voucher-input voucher-input-checkout" style="margin-bottom: 30px">
          <div class="add-fix">
            <form v-on:submit.prevent="">
              <input id="couponvalue" style="flex: inherit; margin-left: 10px" type="text" name="" class="ol-input"
                placeholder="Masukkan Kode Voucher" v-model="input.kode" />
              <button v-show="!loadingVoucher && !appliedVoucher" style="margin-right: 10px" type="button"
                class="ol-btn-coupon" @click="terapkan()">
                Gunakan
              </button>
              <button v-show="!loadingVoucher && appliedVoucher" style="margin-right: 10px" type="button"
                class="ol-btn-coupon" @click="hapusVoucher()">
                Batal
              </button>
            </form>
          </div>
        </div>
        <div class="row" style="justify-content: center">
          <ul class="popup-card-vouchers">
            <li class="popup-card-voucher" v-for="voucher in data" :key="voucher.id">
              <div class="main">
                <div class="content-voucher">
                  <h2>
                    <img :src="assetIcon('discount-red.png')" width="15" />
                    Diskon &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span v-if="voucher.show_on === 1">{{
                      voucher.voucher_rp
                    }}</span>
                    <span v-else-if="voucher.show_on === 3">{{
                      voucher.max_discount_price_rp
                    }}</span>
                    <span v-else-if="voucher.show_on === 4">{{ voucher.percentage }}%</span>
                  </h2>
                  <div class="break-1"></div>
                  <p v-if="voucher.min_purchase_rp != 'Rp0'">
                    Min. Transaksi {{ voucher.min_purchase_rp }}
                  </p>
                  <p v-else>Tanpa Min. Transaksi</p>
                  <p>Hingga {{ voucher.expired }}</p>
                </div>
              </div>
              <div class="copy-button">
                <button v-on:click="gunakan(voucher)" class="copybtn">
                  Gunakan
                </button>
              </div>
            </li>
          </ul>
        </div>
      </Popup>
    </div>
    <Popup :title="title" id="addAddress">
      <LoadingImg v-if="loading.save" />
      <div>
        <Alert v-show="validateBackend != null" type="danger" :collection="validateBackend" />

        <div class="ol-input-box ol-input-box--full">
          <label class="ol-input-label">Nama Lengkap</label>
          <input type="text" class="ol-input" v-model="input.name" />
        </div>
        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">Telepon</label>
          <input type="number" class="ol-input" v-model="input.phone" />
        </div>
        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">Provinsi</label>
          <select id="provinces" class="ol-input ol-input-select select2"></select>
        </div>
        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">Kota/Kabupaten</label>
          <select id="cities" class="ol-input ol-input-select select2"></select>
        </div>
        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">Kecamatan</label>
          <select id="districts" class="ol-input ol-input-select select2"></select>
        </div>
        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">Kelurahan</label>
          <select id="villages" class="ol-input ol-input-select select2"></select>
        </div>
        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">Kode Pos</label>
          <input type="text" class="ol-input" v-model="input.postal_code" />
        </div>
        <div class="ol-input-box ol-input-box--full">
          <label class="ol-input-label"> Alamat </label>
          <textarea class="ol-input" v-model="input.address"></textarea>
          <label class="ol-product-content__sidebar-item-list-item-link">
            <input class="ol-input--radio-check" v-model="input.default" true-value="1" false-value="0"
              type="checkbox" />
            Simpan sebagai alamat utama
            <i class="ol-input--radio-checkmark dt-input--radio-checkmark--radio"></i>
          </label>
        </div>

        <div class="ol-input-box ol-input-box--half">
          <button type="button" name="" v-on:click="savingAddress()" class="ol-btn">
            Simpan
          </button>
        </div>
      </div>
    </Popup>
    <Popup title="Daftar Alamat" id="listAddress" ref="paymentPopup">
      <div class="ol-checkout-content__shipping-list" style="margin-bottom: 0">
        <div class="ol-checkout-content__shipping-list-head">
          <div class="ol-checkout-content__shipping-list-head-item">
            Penerima
          </div>
          <div class="ol-checkout-content__shipping-list-head-item">Alamat</div>
          <div class="ol-checkout-content__shipping-list-head-item"></div>
        </div>
        <div class="d-flex flex-column ol-checkout-content__shipping-list-body" style="padding: 0px 8px 0px 8px">
          <form style="max-height: 350px; overflow-y: scroll;">
            <label v-for="item in addresses" :key="item.id" class="ol-checkout-content__shipping-list-body-wrapper">
              <div class="ol-checkout-content__shipping-list-body-item" style="display: block">
                <label class="ol-checkout-content__shipping-list-body-item-label">
                  <input class="ol-input--radio-check" name="default-address" type="radio" :value="item"
                    v-model="pickedAddress" />
                  <i
                    class="ol-input--radio-checkmark dt-input--radio-checkmark--radio ol-input--radio-checkmark--dd"></i>
                </label>
              </div>
              <div class="ol-checkout-content__shipping-list-body-item">
                <p>
                  <b>{{ item.name }}</b>
                </p>
                <span>{{ item.phone }}</span>
              </div>
              <div class="ol-checkout-content__shipping-list-body-item">
                {{ item.address }}, {{ item.postal_code }},
                {{ item.village_name }}, {{ item.district_name }},
                {{ item.city_name }}, {{ item.province_name }}
              </div>
              <div class="ol-checkout-content__shipping-list-body-item">
                <a title="Edit" class="ol-btn ol-btn--secondary" v-on:click="edit(item.id)"><i
                    class="far fa-edit"></i></a>
                <!-- <a class="ol-btn ol-del" style="margin-left:8px;" v-on:click="del(item.id)"><i class="fas fa-trash"></i></a> -->
              </div>
            </label>
          </form>
          <button class="ol-address-add-btn align-self-center" style="margin-top: 16px; width: 95%"
            @click="confirmAndClosePopup">
            Konfirmasi
          </button>
        </div>
      </div>
    </Popup>
    <Popup title="Pilih Metode Pembayaran" id="listPayment" ref="paymentPopup">
      <div class="ol-checkout-content" style="padding: 0 15px">
        <div class="ol-checkout-content__payment">
          <accordion-payment :payment_list="paymentList" :last_payment="user.last_payment_code"
            @selectedPayment="handleSelectedPayment($event)" @selectedTenor="handleSelectedTenor($event)"
            @fee="handleFee($event)"></accordion-payment>
          <button class="ol-address-add-btn" style="margin-top: 16px" @click="confirmAndClosePopup">
            Konfirmasi
          </button>
        </div>
      </div>
    </Popup>
    <a href="#" class="ol-totop"><i class="ol-fa fas fa-arrow-up"></i></a>
    <!-- <footer class="ol-footer-checkout">
      <div class="ol-wrapper ol-wrapper--small">
        <nav class="ol-footer-checkout__nav">
          <router-link to="/snk" target="_blank"
            >Syarat dan Ketentuan</router-link
          >
          |
          <router-link to="/faq" target="_blank">FAQ</router-link>
        </nav>

        <div class="ol-footer-checkout__copyright">
          <img :srcset="assets('morelogo_nd.png')" alt="MORE" title="MORE" />
          &copy; {{ new Date().getFullYear() }}. Hak Cipta Dilindungi
        </div>
      </div>
    </footer> -->
    <!-- <Footer /> -->
    <div>
      <!-- ol-footer -->
      <nav class="ol-footer-nav p-0 d-flex align-items-center justify-content-end">
        <div class="ol-wrapper ol-wrapper--large d-flex flex-column align-items-end justify-content-between"
          style="flex: 2">
          <p>Total Pembayaran</p>
          <p class="text-colored fw-bold">
            <b>{{
              summary.summary.bill_total_rp
                ? summary.summary.bill_total_rp
                : summary.summary.grand_total_rp
            }}</b>
          </p>
        </div>
        <a href="javascript:void(0)" v-if="summary && !loadingSummary && !loadingBayar" type="button" @click="saveTr()"
          :class="loadingSummary || !summary.allProductsIsValid
            ? 'ol-btn ol-btn-disable '
            : 'ol-btn '
            " style="flex: 1">
          Buat Pesanan
          <!-- <i class="ol-fa fas fa-arrow-right"></i> -->
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import globalUrl from "@/util/globallUrl";
import helpme from "@/util/helpme";
import select2 from "@/lib/select2";
import AccordionPayment from "@/components/transaction/checkout/AccordionPayment.vue";
import BannerBottomLoading from "@/components/loading/BannerBottomLoading";
import Header from "@/components/transaction/checkout/Header.vue";
import SkeletonLoader from "@/components/loading/skeletonLoader";
import Popup from "@/components/popup/Popup.vue";
const UNI = globalUrl.WS_UNI;
const BASE = process.env.VUE_APP_BASE_API_URL + "/";

export default {
  name: "Buy",
  components: {
    AccordionPayment,
    BannerBottomLoading,
    Header,
    Popup,
    SkeletonLoader,
  },
  data() {
    return {
      title: null,
      loading: false,
      loadingSummary: false,
      loadingBayar: false,
      loadingVoucher: false,
      appliedVoucher: false,
      loadingAddress: false,
      wa_no: "",
      wa_link: "",
      alertVoucher: {
        showing: false,
        pesan: "",
        type: "",
      },
      data: [],
      modal: {
        showing: false,
        data: null,
      },
      input: {
        kode: "",
        data: null,
      },
      queue: null,
      alertList: {
        message: null,
        type: "info",
      },
      picked: null,
      pickedAddress: null,
      tenor: null,
      fee: null,
      validateBackend: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "user"]),
    ...mapGetters("home", ["metaPages", "waText"]),
    ...mapGetters("transaction/cart", [
      "variants",
      "grandtotal",
      "voucher",
      "grandtotalVoucher",
      "diskon",
      "note",
      "grandtotalHemat",
      "grandtotalNormal",
    ]),
    ...mapGetters("transaction/checkout", [
      "shipping",
      "payment",
      "summary",
      "order",
      "dropship",
      "paymentList",
    ]),
    ...mapGetters("product/detail", ["recommended", "getUtmContent"]),
    ...mapGetters("profile/address", ["provinces", "addresses"]),
    addressActive() {
      return _.filter(this.addresses, { default: 1 });
    },
    addressNonActive() {
      return _.filter(this.addresses, { default: 0 });
    },
    fee_rp: function () {
      return helpme.toRp(this.fee);
    },
    tenor_txt: function () {
      if (this.tenor > 0) return this.tenor + " bulan";
      return "";
    },
  },
  methods: {
    ...mapActions("home", ["fetchMetaPages", "getSetting"]),
    ...mapActions("transaction/cart", ["set_voucher", "save", "get"]),
    ...mapActions("transaction/checkout", [
      "fetchSummary",
      "saveTransaction",
      "getIpPublic",
      "fetchPaymentList",
    ]),
    ...mapActions("product/detail", ["removeUtmContent"]),
    ...mapActions("profile/address", [
      "fetchProvinces",
      "fetchAddresses",
      "saveAddress",
      "updateAddress",
      "delAddress",
    ]),
    handleSelectedPayment(value) {
      let dataPayment = _.flatMap(this.paymentList, (item) =>
        this.filterMatched(item, value)
      );
      this.picked = _.head(dataPayment);
    },
    handleSelectedTenor(value) {
      this.tenor = value;
      this.$store.commit("transaction/checkout/setTenor", value);
    },
    handleFee(value) {
      this.fee = value;
      this.$store.commit("transaction/checkout/setFee", value);
    },
    filterMatched(x, value) {
      let matched = _.filter(x, function (y) {
        if (y.code == value) return y;
      });
      return matched;
    },
    showPopupListPayment(show = true) {
      show
        ? Popup.methods.show("listPayment")
        : Popup.methods.hide("listPayment");
    },
    showPopupListAddress(show = true) {
      show
        ? Popup.methods.show("listAddress")
        : Popup.methods.hide("listAddress");
    },
    showPopupAddAddress(show = true, isNew = true) {
      this.title = "Edit Alamat";
      if (isNew) {
        this.title = "Tambah Alamat";
        this.input.id = null;
        this.input.name = null;
        this.input.address = null;
        this.input.province = null;
        this.input.city = null;
        this.input.district = null;
        this.input.village = null;
        this.input.postal_code = null;
        this.input.phone = null;
        this.input.default = 0;
        $("select#provinces,select#cities,select#districts,select#villages")
          .val(null)
          .trigger("change");
        Popup.methods.hide("listAddress");
      }
      show
        ? Popup.methods.show("addAddress")
        : Popup.methods.hide("addAddress");
      this.validateBackend = null;
      this.loading.save = false;
    },
    edit(id) {
      Popup.methods.hide("listAddress");

      this.title = "Ubah Alamat";
      const address = _.find(this.addresses, { id: id });
      this.input.id = address.id;
      this.input.name = address.name;
      this.input.address = address.address;
      this.input.province = address.province;
      this.input.city = address.city;
      this.input.district = address.district;
      this.input.village = address.village;
      this.input.postal_code = address.postal_code;
      this.input.phone = address.phone;
      this.input.default = Number(address.default);
      // $("select#provinces").val(this.input.province).trigger("change");
      $("select#provinces")
        .append(new Option(address.province_name, address.province, true, true))
        .trigger("change");
      $("select#cities")
        .append(new Option(address.city_name, address.city, true, true))
        .trigger("change");
      $("select#districts")
        .append(new Option(address.district_name, address.district, true, true))
        .trigger("change");
      $("select#villages")
        .append(new Option(address.village_name, address.village, true, true))
        .trigger("change");
      Popup.methods.show("addAddress");
    },
    del(id) {
      this.alertList.message = null;
      Swal.fire({
        text: "Yakin ingin dihapus?",
        icon: "warning",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#364b7b",
        cancelButtonColor: "#d33",
        confirmButtonText: "Hapus",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading.list = true;
          const result = await this.delAddress(id);
          if (result.success) {
            this.message = "Berhasil menghapus alamat";
            this.alertList.message = "Berhasil menghapus alamat";
            this.alertList.type = "success";
            this.loading.list = false;
          } else {
            this.alertList.message = result.data.message
              ? result.data.message
              : "Gagal menghapus alamat, coba beberapa saat lagi";
            this.alertList.type = "danger";
            this.loading.list = false;
          }
        }
      });
    },

    detailVoucher(data) {
      this.modal.data = data;
      this.modal.showing = true;
    },
    async chatDelivery(product, city, province) {
      let texts =
        "Hai MORE saya ingin bertanya *Produk : " +
        product +
        "*, Ongkos kirim ke : *" +
        city +
        " Provinsi " +
        province +
        "*";
      let sendWa = {
        phone: process.env.VUE_APP_WA_CS,
        text: texts,
      };
      window.open("https://api.whatsapp.com/send?" + $.param(sendWa), "_blank");
    },
    async _getSetting() {
      let setting = await this.getSetting();
      let wa = _.find(setting, { id: 8 });
      if (wa) this.wa_no = wa.content;
      if (wa) this.wa_link = wa.description;
    },
    async gunakan(data) {
      if (data) {
        this.input.kode = data.code;
        await this.terapkan();
        this.showPopupVoucher(false);
      }
    },
    async terapkan() {
      if (this.input.kode) {
        this.showPopupVoucher(false);
        this.appliedVoucher = false;
        $("#couponvalue").prop("disabled", false);
        $("#infopesan").hide();

        this.loadingVoucher = true;
        let result = await this.set_voucher(this.input.kode);
        this.loadingVoucher = false;
        if (result.success) {
          this.alertVoucher.pesan = "Voucher berhasil diterapkan.";
          this.appliedVoucher = true;
          // $("#couponvalue").prop("disabled", true);

          $("#infopesan").removeClass("promoalertfail").slideDown("fast");

          this.$store.commit(
            "transaction/checkout/setSummaryWithDiscount",
            this.voucher.potongan
          );
        } else {
          this.alertVoucher.showing = true;
          this.alertVoucher.pesan = result.data;
          $("#infopesan").addClass("promoalertfail").slideDown("fast");
        }
      }

      if (!this.input.kode) {
        $("#infopesan").slideUp("fast");
      }
    },
    hapusVoucher() {
      this.$store.commit("transaction/cart/setVoucher", null);
      this.appliedVoucher = false;
      this.input.kode = null;
      $("#couponvalue").prop("disabled", false);
      var y = document.getElementById("infopesan");
      y.style.display = "none";
    },
    renderPotongan(voucher) {
      if (voucher.type_discount == 1) {
        return helpme.toRp(voucher.voucher);
      } else if (voucher.type_discount == 2) {
        return "Diskon " + voucher.voucher + "%";
      }
    },
    confirmAndClosePopup() {
      // Hide the popup by calling the `hide` method on the Popup component using the ref.
      this.$refs.paymentPopup.hide("listPayment");
      this.$refs.paymentPopup.hide("listAddress");
    },
    jqAddress() {
      let input = this.input;
      let villages = null;

      select2.ajax(
        "select#provinces",
        BASE + UNI._PROVINCES,
        "province_id",
        "province_name"
      );

      $("select#provinces").change(function () {
        input.province = $(this).val();
        if (input.province) {
          $("select#cities,select#districts,select#villages")
            .val(null)
            .trigger("change");
          select2.ajax(
            "select#cities",
            BASE + UNI._CITIES + "?province_id=" + input.province,
            "city_id",
            "city_name"
          );
        }
      });

      $("select#cities").change(function () {
        input.city = $(this).val();
        if (input.city) {
          $("select#districts,select#villages").val(null).trigger("change");
          select2.ajax(
            "select#districts",
            BASE + UNI._DISTRICTS + "?city_id=" + input.city,
            "district_id",
            "district_name"
          );
        }
      });

      $("select#districts").change(function () {
        input.district = $(this).val();
        if (input.district) {
          $("select#villages").val(null).trigger("change");
          select2.ajax(
            "select#villages",
            BASE + UNI._VILLAGES + "?district_id=" + input.district,
            "village_id",
            "village_name",
            function (result) {
              villages = result;
            }
          );
        }
      });

      $("select#villages").change(function () {
        input.village = $(this).val();
        if (input.village) {
          let village = _.find(villages, { village_id: input.village });
          if (village) {
            input.postal_code =
              village.village_postcode > 0 ? village.village_postcode : null;
          }
        }
      });
    },
    jq() {
      $(".ol-checkout-couponcarousel-list").slick({
        slidesToScroll: 1,
        slidesToShow: 3,
        dots: !1,
        autoplay: !0,
        autoplaySpeed: 5e3,
        arrows: false,
        pauseOnFocus: !1,
        infinite: !0,
        speed: 500,
        fade: !1,
        responsive: [
          {
            breakpoint: 891,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    },
    async getVoucher() {
      this.loading = true;
      await globalUrl.WS_TRANSACTION.CART.GET_VOUCHER()
        .then((response) => {
          this.data = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          // console.log(error.data)
          this.loading = false;
        });
      this.jq();
    },
    async getSummary() {
      if (this.shipping) {
        this.loadingSummary = true;
        let data = { city_id: this.shipping.city };
        if (this.input.kode) {
          data.address_id = this.shipping.id;
          data.voucher = this.input.kode;
          data.payment_code = this.payment.code;
        }
        // TODO: biaya layanan
        // data.fee = this.fee;
        await this.fetchSummary(data);
        this.loadingSummary = false;
      }
    },
    async saveTr() {
      if (!this.order || this.order == null) {
        $("#infopesan").slideUp("fast");

        let affiliate = null;
        if (this.getUtmContent.length > 0) {
          let ip = await this.getIpPublic();
          affiliate = {
            utm_content: this.getUtmContent,
            ip_device: ip,
            name_device: this.$browserDetect.meta.ua,
          };
        }

        let data = {
          voucher: this.input.kode,
          city_id: this.shipping.city,
          address_id: this.shipping.id,
          payment_code: this.payment.code,
          note: this.note,
          dropshipper_name: this.dropship ? this.dropship.name : null,
          dropshipper_phone: this.dropship ? this.dropship.phone : null,
          // TODO: biaya layanan
          // fee: this.fee,
          tenor: this.tenor,
          affiliate,
        };

        let parsedItems = _.map(this.variants, (q) => {
          return {
            id: q.r_uni_product.id,
            name: q.r_uni_product.name,
            brand: q.r_uni_product.brand,
            variant: q.name_long,
            quantity: q.quantity,
            price: q.subtotal,
          };
        });
        this.$gtag.event("purchase", {
          total: this.summary.summary.bill_total,
          products: parsedItems,
        });

        // this.analytics("InitiateCheckout", {
        // 	content_type: "product",
        // 	value: this.summary.summary.bill_total,
        // 	num_items: _.sumBy(this.summary.cart_items, "quantity"),
        // 	currency: "IDR",
        // });
        fbq("track", "InitiateCheckout", {
          content_category: "Checkout",
          // content_ids: "",
          // contents: "",
          currency: "IDR",
          num_items: _.sumBy(this.summary.cart_items, "quantity"),
          value: this.summary.summary.bill_total,
        });

        this.loadingBayar = true;
        let result = await this.saveTransaction(data);
        if (result.statusCode == 500)
          this.$toast.open({
            message: "Gagal menyimpan transaksi!",
            type: "error",
            duration: 5000,
          });

        if (result.success) {
          // this.removeUtmContent(); //Hapus UTM Content ketika Checkout
          this.$store.commit("transaction/cart/setNullPostOrder");
          this.$store.commit("transaction/checkout/setDropship", null);
          if (result.data.faspay_response.redirect_url) {
            window.location.href = result.data.faspay_response.redirect_url;
          } else {
            this.loadingBayar = false;
            this.$router.push("/checkout/success");
          }
        } else {
          this.loadingBayar = false;
          if (result.data.voucher.message) {
            // this.input.kode = result.data.voucher.request_code;
            this.alertVoucher.pesan = result.data.voucher.message;
            $("#infopesan").addClass("promoalertfail").slideDown("fast");
          }
          $("html, body").animate(
            {
              scrollTop: $("div.order-sel-paymethod").offset().top,
            },
            500
          );
        }
      } else {
        this.$router.push("/profile/orders");
      }
    },
    showPopupVoucher(show = true) {
      show
        ? Popup.methods.show("popupVoucher")
        : Popup.methods.hide("popupVoucher");
    },
    async savingAddress() {
      this.alertList.message = null;
      this.validateBackend = null;
      this.loadingAddress = true;
      const result = !this.input.id
        ? await this.saveAddress(this.input)
        : await this.updateAddress(this.input);
      if (result.success) {
        if (this.addresses.length == 1) {
          this.pickedAddress = _.head(this.addresses);
        }
        Popup.methods.hide("addAddress");
        this.alertList.message = "Berhasil menyimpan alamat";
        this.alertList.type = "success";
      } else {
        this.validateBackend = result.data.message
          ? { address: [" Gagal menyimpan alamat, coba beberapa saat lagi"] }
          : result.data;
        this.loadingAddress = false;
        // this.showPopup(false)
      }
    },
  },
  watch: {
    "input.kode": function () {
      $("#infopesan").slideUp("fast");
      this.getSummary();
    },
    picked: function (value) {
      if (value) {
        this.$store.commit("transaction/checkout/setPayment", value);
        this.getSummary();
        // $("a#payments-btn-next").removeClass("ol-btn-disable");
      } else {
        // $("a#payments-btn-next").addClass("ol-btn-disable");
      }
    },
    pickedAddress: function (value) {
      this.$store.commit("transaction/checkout/setShipping", value);
      this.getSummary();
    },
  },
  async mounted() {
    this.jqAddress();
    await Promise.all([
      this.fetchPaymentList(),
      this.fetchAddresses(),
      this.getVoucher(),
    ]);

    let dataPayment = _.flatMap(this.paymentList, (item) =>
      this.filterMatched(item, this.user.last_payment_code)
    );
    this.picked = _.head(dataPayment);
    this.pickedAddress = _.find(this.addresses, { default: 1 });

    await this.getSummary();
    if (this.shipping) {
      let parsedItems = _.map(this.variants, (q) => {
        return {
          id: q.r_uni_product.id,
          name: q.r_uni_product.name,
          brand: q.r_uni_product.brand,
          variant: q.name_long,
          quantity: q.quantity,
          price: q.subtotal,
        };
      });
      this.$gtag.event("checkout", {
        total: this.summary.summary.bill_total,
        products: parsedItems,
      });
    }
    this._getSetting();
    // this.analytics("AddPaymentInfo");
    // if(this.voucher.code){
    //     this.input.kode = this.voucher.code;
    //     this.terapkan();
    // }
    if (this.addresses.length == 0) {
      this.showPopupAddAddress(true, true);
    }
  },
};
</script>

<style scoped lang="css">
@import "../../../assets/css/coupon.css";
@import "../../../assets/css/cart.css";

.ol-btn-disable {
  background-color: #aaa;
  border-color: #aaa;
  cursor: default;
  pointer-events: none;
}

.popup-card-vouchers {
  width: 100%;
  margin-top: -20px;
}

.popup-card-voucher {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  padding: 20px;
  background: var(--white);
  border-radius: 8px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 15%);
  scroll-snap-align: start;
  transition: all 0.2s;
  margin: 0 15px 15px 15px;
}

.product {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: 0px solid #eee;
}

.ol-alert-static {
  margin-bottom: 0px;
}

.btn-hubungi-admin {
  text-align: right;
}

.info-hubungi-admin {
  font-size: 12px;
}

.promoalert {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .info-hubungi-admin {
    font-size: 10px;
  }

  .btn-hubungi-admin {
    text-align: center;
    margin-top: 2px;
  }

  .ol-alert-static {
    margin-top: 4px !important;
  }
}
</style>

<style scoped>
.bold {
  font-weight: bold;
}

.gap-5px {
  gap: 5px;
}

.p-0 {
  padding: 0;
}

.pc-only {
  display: none !important;
}

.pc-only-flex {
  display: none !important;
}

.py-8px {
  padding-top: 8px;
  padding-bottom: 8px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.ol-structure {
  flex-direction: column;
}

.ol-structure>div {
  flex-direction: column;
}

.container-address {
  border-bottom: 5px solid #eee;
  margin-top: 15px;
  padding: 0 10px 15px 10px;
}

.container-product {
  border-bottom: 5px solid #eee;
  margin-top: 15px;
  padding: 0 10px 15px 10px;
}

.container-voucher {
  border-bottom: 5px solid #eee;
  padding: 0 10px 0 10px;
}

.container-payment {
  border-bottom: 5px solid #eee;
  padding: 0 10px 0 10px;
}

.container-payment-detail {
  border-bottom: 5px solid #eee;
  margin-bottom: 70px;
  padding: 15px 10px 15px 10px;
}

.container {
  padding-left: 7px;
  padding-right: 7px;
}

.container>form {
  padding: 0 0;
  width: 100%;
}

.container-voucher>form>.voucher-button {
  padding: 15px 0;
  width: 100%;
}

.container>form>.voucher-code-input {
  display: none;
}

.container>form>.voucher-cancel {
  display: none;
}

.container .payment-button {
  padding: 15px 0;
  width: 100%;
}

.text-colored {
  color: #c13b37;
}

.address-content-container {
  margin-bottom: 15px;
}

.total-estimation .total {
  color: #c13b37;
  font-weight: bold;
}

.ol-cart-list__footer-item {
  margin-bottom: 0;
  padding: 0;
}

.address-shipping-name {
  font-weight: bold;
}

.ol-address-add-btn {
  align-items: center;
  display: flex;
  background-color: #eef4fc;
  border: #97bdec solid 1px;
  border-radius: 8px;
  color: #364b7b;
  font-weight: 600;
  justify-content: center;
  padding: 12px 16px;
  width: 100%;
}

.ol-footer-nav.d-flex .text-colored.fw-bold {
  font-weight: bold;
  font-size: 16px;
}

.ol-footer-nav.d-flex .ol-btn {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: center;
  min-height: 60px;
}

.ol-footer-nav .ol-btn {
  border-radius: 8px 0 0 8px;
}

@media (min-width: 768px) {
  .pc-only {
    display: block !important;
  }

  .pc-only-flex {
    display: flex !important;
  }

  .mobile-only {
    display: none !important;
  }

  .ol-structure {
    align-items: flex-start;
    background-color: transparent !important;
    flex-direction: row;
    gap: 16px;
  }

  .ol-structure>div {
    gap: 16px;
  }

  .ol-structure>div:nth-child(1) {
    flex: 5;
  }

  .ol-structure>div:nth-child(2) {
    flex: 4;
  }

  .ol-background {
    background-color: #f3f4f7;
    min-height: 100vh;
  }

  .ol-cart-list__footer-item {
    float: none !important;
  }

  .ol-cart-list__footer-item .ol-cart-list__footer-item-total {
    color: #6c7c93;
    display: flex;
    font-size: 12px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .ol-cart-list__footer-item .ol-cart-list__footer-item-total dt {
    padding: 0 0 4px 0;
    width: 50%;
  }

  .ol-cart-list__footer-item .ol-cart-list__footer-item-total dt:last-child {
    padding: 0 0 8px 0;
  }

  .ol-cart-list__footer-item .ol-cart-list__footer-item-total .title {
    color: #141414;
    font-size: 16px;
    line-height: 22.4px;
  }

  .ol-cart-list__footer-item .ol-cart-list__footer-item-total .title strong {
    color: #141414;
  }

  .container {
    background-color: white;
    border-radius: 8px;
    padding: 16px;
  }

  .container>h2 {
    border-bottom: none;
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    margin-bottom: 16px;
  }

  .container-address,
  .container-product,
  .container-payment,
  .container-payment-detail {
    border-bottom: none;
    margin-top: 0;
  }

  .container-product .ol-table {
    border-collapse: separate;
    border-spacing: 16px;
    font-size: 12px;
    color: #141414;
    width: 100%;
  }

  .container-product .ol-table .title {
    font-size: 14px;
    line-height: auto;
  }

  .container-product .ol-table img {
    object-fit: contain;
    min-width: 41px;
    width: 3.21vw;
  }

  .container-product .ol-table .product-image {
    width: 100%;
  }

  .container-product .ol-table .product-image h3 {
    color: #141414;
    font-size: 12px;
    line-height: auto;
  }

  .container-product .ol-table .product-details {
    float: none;
    width: 100%;
    word-wrap: normal;
  }

  .container-product .ol-table .product-price {
    float: none;
    width: 100%;
    word-wrap: normal;
  }

  .container-product .ol-table .product-quantity {
    float: none;
    width: 100%;
    word-wrap: normal;
  }

  .container-product .ol-table .product-quantity {
    float: none;
    width: 100%;
    word-wrap: normal;
  }

  .container-product .ol-table .product-line-price {
    float: none;
    width: 100%;
    word-wrap: normal;
  }

  .container-payment-detail form .voucher-button {
    background-color: #fcf3f2;
    border-radius: 4px;
    color: #ca352b;
    font-size: 14px;
    line-height: 18.2px;
    margin-bottom: 8px;
    padding: 12px 12px;
    padding-right: 22px;
    width: 100%;
  }

  .container-payment-detail form .voucher-button>p {
    display: flex;
  }

  .container-payment-detail a.ol-btn {
    background-color: #ca352b;
    display: block;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 16px;
  }

  .address-content-container {
    gap: 16px;
  }

  .address-content-container .address-shipping-name,
  .address-content-container .address-shipping-phone {
    color: #6c7c93;
    font-size: 12px;
    font-weight: bold;
    line-height: 16.8px;
  }

  .address-content-container .address-shipping-detail {
    color: #6c7c93;
    font-size: 12px;
    line-height: 16.8px;
  }

  .address-content-container .ol-fa {
    color: #364b7b;
    font-size: 15px;
  }

  .ol-address-add-btn {
    font-size: 18px;
    gap: 20px;
    margin-left: 0;
  }

  .ol-address-add-btn>.ol-fa {
    filter: drop-shadow(0 0 0 black);
  }

  #accordion-payment {
    background-color: #eef4fc;
    border: #97bdec solid 1px;
    border-radius: 8px;
    color: #364b7b;
  }

  .ol-footer-nav.d-flex {
    background-color: #364b7b;
    color: #ffffff;
    font-size: 10px;
    padding: 10px;
  }

  .promoalert {
    margin-bottom: 16px;
    margin-top: 0;
  }

  .ol-product-content__sidebar-item-list-item-link {
    line-height: 1em;
    padding-top: 0;
  }

  .voucher-image {
    margin-right: 16px;
    height: 100%;
    max-height: 18px;
  }
}
</style>